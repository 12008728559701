// Importing Redux actions for employer registration.
import { 
    employerRegisterRequest, 
    employerRegisterSuccess, 
    employerRegisterFailure,
    employerListRequest,
    employerListSuccess,
    employerListFailure ,
    updateEmployerFailure,
    updateEmployerSuccess,
    employerDetailsData,setAddJobSuccess,setAddJobFailure,
    setJobsList,
    setBulkJobsPostFailure,setBulkJobsPostSuccess,
    setJobsDetails,
    setUpdateJobSuccess, setUpdateJobFailure,
    setBulkJobsList,
    setMatchedProfilesList,
    profileUpdateStatusSuccess,
    profileUpdateStatusFailure,
    messageSentFailed,
    messageSentSuccess,
    noteSentSuccess,
    noteSentFailed,
    setMessageList,
    setNoteList,
    setMatchedJobsList,
    setFollowingCommunities,
    setSubscribedCommunities,
    bulkExternalUploadSuccess,
    bulkExternalUploadFailed,
    setExternalJobsList,
    jobsListRequest,
    setMatchProfileListRequest,
    matchedJobsRequest,
    subscribeListRequest,
    empCandidatesMatchesReq,
    setEmpCandidateMatches,
    setEmpTopSkills, 
    empTopSkillsReq,
    setJoinedGroups,
    joinedGroupRequest,
    setRecentLogins, 
    recentLoginsReq,
    hireStatsRequest,
    rejectedStatsRequest,
    interviewStatsRequest,
    setHiredStats, setInterviewdStats, setRejectedStats,
    recentMessagesReq, setRecentMessages,
    setHiredAvgDays, hiredAvgDaysRequest,
    rejectedAvgDaysRequest, setRejectedAvgDays,
    requestTopAppliedJobs, setTopAppliedJobs,
    setAppliedTalentList, requestAppliedTalent
  } from '../redux/slices/employerSlice';
  
  // Abstracted service for API requests.
  import {apiService} from './apiService';
  
  // Utility function to display toast notifications.
  import { showToast } from '../views/Utils';
  
  /**
   * Initiates the registration process for an employer using an invitation.
   * 
   * @param {Object} payLoad - Contains the employer registration details.
   * @returns {Promise} - Returns the server response or handles the error.
   */
  export const employerRegister = (payLoad) => async (dispatch) => {
      // Indicates the start of the registration request process.
      dispatch(employerRegisterRequest());
  
      try {
          // Makes an API call to register the invited user.
          const response = await apiService.post('users/invited-user-register', payLoad);
          const returnData = response.data?.result;
  
          // Updates the Redux store with the registered employer details.
          dispatch(employerRegisterSuccess(returnData));
      } catch (error) {
          // Extracts and handles the error message from the API response.
          const errorMessage = error.response?.data?.message || error.message;
          dispatch(employerRegisterFailure(errorMessage));
  
          // Notifies the user of the error via a toast notification.
          showToast(errorMessage, 'error');
      }
  };

   /**
   * To get the latest employer List
   * 
   * @param {Object} payLoad - Contains list filtering details.
   * @returns {Promise} - Returns the server response or throws an error.
   */
 export const getEmployerList = (payLoad,cancelTokenSource) => async (dispatch) => {
    // Dispatches an action to indicate the registration request has started.
    dispatch(employerListRequest());
 
    try {
        // Makes an API call to register the invited user.
        const response = await apiService.post('employer/list', payLoad,{ signal: cancelTokenSource?.signal});
        const returnData = response.data?.result;

        // Dispatches an action to store the get employer list in the redux store.
        dispatch(employerListSuccess(returnData));
    } catch (error) {
        if(error?.message == 'canceled'){
            dispatch(employerListRequest())
        }else{
        // Handles any errors that occur during the API request.
        const errorMessage = error.response?.data?.message || error.message;

        // Dispatches an action to show get employer list request failed.
        dispatch(employerListFailure(errorMessage));
        }

    }
};
export const updateEmployerInfo = (payLoad) => async (dispatch) => {
   
    try {
        const response = await apiService.post('employer/update', payLoad);
        const returnData = response.data?.result.message;
        dispatch(updateEmployerSuccess(returnData));
        //console.log("returnData",returnData)
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message;
        dispatch(updateEmployerFailure(errorMessage));
       // console.log("errorMessage",errorMessage)

    }
};
export const employerDatails = (payLoad) => async (dispatch) => {
   
    try {
        const response = await apiService.post('employer/details', payLoad);
        const returnData = response.data?.result;
        dispatch(employerDetailsData(returnData));
        //console.log("returnData",returnData)
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message;
       // console.log("errorMessage",errorMessage)

    }
};
  

  /**
   * Initiates the Add new job.
   * @param {Object} payLoad - Contains thenew job details.
   * @returns {Promise} - Returns the server response or handles the error.
   */
  export const addJob = (payLoad) => async (dispatch) => {
    // Indicates the start of the Add new jobrequest process.
    dispatch(employerRegisterRequest());

    try {
        // Makes an API call to register the invited user.
        const response = await apiService.post('jobs/addsinglejob', payLoad);
        const returnData = response.data?.result.message;

        // Updates the Redux store with the registered employer details.
        dispatch(setAddJobSuccess(returnData));
    } catch (error) {
        // Extracts and handles the error message from the API response.
        const errorMessage = error.response?.data?.message || error.message;
        dispatch(setAddJobFailure(errorMessage));

        // Notifies the user of the error via a toast notification.
        showToast(errorMessage, 'error');
    }
};
  /**
   * Initiates the a request to get jobs list.
   * @param {Object} payLoad - Contains the filter details.
   * @returns {Promise} - Returns the server response or handles the error.
   */
  export const getJobsList = (payLoad,cancelTokenSource) => async (dispatch) => {
    // Indicates the start of the Add new jobrequest process.
    dispatch(jobsListRequest());


    try {
        // Makes an API call to register the invited user.
        const response = await apiService.post('jobs/list', payLoad,{ signal: cancelTokenSource?.signal});
        const returnData = response.data?.result;

        // Updates the Redux store with the registered employer details.
        dispatch(setJobsList(returnData));
    } catch (error) {
        if(error?.message == 'canceled'){
            dispatch(jobsListRequest())
        }else{
        // Extracts and handles the error message from the API response.
        const errorMessage = error.response?.data?.message || error.message;
        dispatch(setJobsList([]));
        }
    }
};

    /**
   * To post jobs in bulk
   * 
   * @param {Object} payLoad - Contains all job details details.
   * @returns {Promise} - Returns the server response or throws an error.
   */
    export const sendBulkJobPost = (payLoad) => async (dispatch) => {
        // Dispatches an action to indicate the bulk jobs post request has started.
        dispatch(employerRegisterRequest());
     
         const config = {
            headers: {
              'Content-Type':  'multipart/form-data',
              // You can add more headers here if needed
            },
          }
        try {
            // Makes an API call to invite the employers or talent in bulk.
            const response = await apiService.post('/jobs/bulk-upload', payLoad,config);
            const returnData = response.data?.result.message;
            // Dispatches an action to store the bulk job posting success response in the redux store.
            dispatch(setBulkJobsPostSuccess(returnData));
        } catch (error) {
            // Handles any errors that occur during the API request.
            const errorMessage = error.response?.data?.message || error.message;
    
            // Dispatches an action to show bulk jobs post request failed.
            dispatch(setBulkJobsPostFailure(errorMessage));
    
        }
    };
    export const getJobDetails = (payLoad) => async (dispatch) => {
        dispatch(employerRegisterRequest());
        try {
            const response = await apiService.post('jobs/details', payLoad);
            const returnData = response.data?.result;
            dispatch(setJobsDetails(returnData));
        } catch (error) {
            // Extracts and handles the error message from the API response.
            const errorMessage = error.response?.data?.message || error.message;
            dispatch(setJobsDetails(""));
        }
    };

// sendEditJobRequest
  /**
   * Initiates the edit job request.
   * @param {Object} payLoad - Contains edited job details.
   * @returns {Promise} - Returns the server response or handles the error.
   */
  export const sendEditJobRequest = (payLoad) => async (dispatch) => {
    // Indicates the start of the  edit job request process.
    dispatch(employerRegisterRequest());

    try {
        // Makes an API call to edit a job based on its id.
        const response = await apiService.post('/jobs/update', payLoad);
        const returnData = response.data?.result.message;

        // Updates the Redux store with the response of edit job request.
        dispatch(setUpdateJobSuccess(returnData));
    } catch (error) {
        // Extracts and handles the error message from the API response.
        const errorMessage = error.response?.data?.message || error.message;
        dispatch(setUpdateJobFailure(errorMessage));

        // Notifies the user of the error via a toast notification.
    }
};
  /**
   * Initiates the a request to get bulk jobs import list.
   * @param {Object} payLoad - Contains the filter details.
   * @returns {Promise} - Returns the server response or handles the error.
   */
  export const getBulkJobsList = (payLoad) => async (dispatch) => {
    // Indicates the start of the bulk jobs import request process.
    dispatch(employerRegisterRequest());

    try {
        // Makes an API call to get bulk jobs import list.
        const response = await apiService.post('jobs/bulk-jobs-list', payLoad);
        const returnData = response.data?.result;

        // Updates the Redux store with the bulk jobs import list.
        dispatch(setBulkJobsList(returnData));
    } catch (error) {
        // Extracts and handles the error message from the API response.
        const errorMessage = error.response?.data?.message || error.message;
        dispatch(setBulkJobsList([]));
    }
};

  /**
   * Initiates the a request to get all the matched profiles list.
   * @param {Object} payLoad - Contains the filter details.
   * @returns {Promise} - Returns the server response or handles the error.
   */
  export const getMatchedProfiles = (payLoad) => async (dispatch) => {
    // Indicates the start of the bulk jobs import request process.
    dispatch(setMatchProfileListRequest());

    try {
        // Makes an API call to get bulk jobs import list.
        const response = await apiService.post('/jobs/matching-profiles-list', payLoad);
        const returnData = response.data?.result;

        // Updates the Redux store with the bulk jobs import list.
        dispatch(setMatchedProfilesList(returnData));
    } catch (error) {
        // Extracts and handles the error message from the API response.
        const errorMessage = error.response?.data?.message || error.message;
        dispatch(setMatchedProfilesList([]));
    }
};
  /**
   * Initiates the a request to change the job application status.
   * @param {Object} payLoad - Contains the details of status update.
   * @returns {Promise} - Returns the server response or handles the error.
   */
  export const updateMatchStatus = (payLoad) => async (dispatch) => {
    // Indicates the start of the update profile job status request process.
    dispatch(employerRegisterRequest());

    try {
        // Makes an API call to  update profile job status.
        const response = await apiService.post('/jobs/update-profile-status', payLoad);
        const returnData = response.data?.result?.message;

        // Updates the Redux store with the response of profile job status update.
        dispatch(profileUpdateStatusSuccess(returnData));
    } catch (error) {
        // Extracts and handles the error message from the API response.
        const errorMessage = error.response?.data?.message || error.message;
        dispatch(profileUpdateStatusFailure(errorMessage));
    }
};
  /**
   * Initiates the a request to send message.
   * @param {Object} payLoad - Contains the details of message.
   * @returns {Promise} - Returns the server response or handles the error.
   */
  export const sendMessage = (payLoad) => async (dispatch) => {
    // Indicates the start of the send message request process.
    // dispatch(employerRegisterRequest());

    try {
        // Makes an API call to send message.
        const response = await apiService.post('/jobs/save-job-messages', payLoad);
        const returnData = response.data?.result.message;

        // Updates the Redux store with the response of sent message.
        dispatch(messageSentSuccess(returnData));
    } catch (error) {
        // Extracts and handles the error message from the API response.
        const errorMessage = error.response?.data?.message || error.message;
        dispatch(messageSentFailed(errorMessage));
    }
};
  /**
   * Initiates the a request to send message.
   * @param {Object} payLoad - Contains the details of message.
   * @returns {Promise} - Returns the server response or handles the error.
   */
  export const sendNote = (payLoad) => async (dispatch) => {
    // Indicates the start of the send message request process.
    // dispatch(employerRegisterRequest());

    try {
        // Makes an API call to send message.
        const response = await apiService.post('/jobs/save-job-notes', payLoad);
        const returnData = response.data?.result.message;

        // Updates the Redux store with the response of sent message.
        dispatch(noteSentSuccess(returnData));
    } catch (error) {
        // Extracts and handles the error message from the API response.
        const errorMessage = error.response?.data?.message || error.message;
        dispatch(noteSentFailed(errorMessage));
    }
};
  /**
   * Initiates the a request to get messages list.
   * @param {Object} payLoad - Contains the details of message list payload.
   * @returns {Promise} - Returns the server response or handles the error.
   */
  export const getMessagesList = (payLoad) => async (dispatch) => {
    // Indicates the start of the get message list request process.
    dispatch(employerRegisterRequest());

    try {
        // Makes an API call to get list of messages.
        const response = await apiService.post('/jobs/job-messages-list', payLoad);
        const returnData = response.data?.result;

        // Updates the Redux store with the response of message list.
        dispatch(setMessageList(returnData));
    } catch (error) {
        // Extracts and handles the error message from the API response.
        const errorMessage = error.response?.data?.message || error.message;
        dispatch(setMessageList([]));
    }
};
  /**
   * Initiates the a request to get notes list.
   * @param {Object} payLoad - Contains the details of notes list payload.
   * @returns {Promise} - Returns the server response or handles the error.
   */
  export const getNotesList = (payLoad) => async (dispatch) => {
    // Indicates the start of the get notes list request process.
    dispatch(employerRegisterRequest());

    try {
        // Makes an API call to get list of notes.
        const response = await apiService.post('/jobs/job-notes-list', payLoad);
        const returnData = response.data?.result;

        // Updates the Redux store with the response of message list.
        dispatch(setNoteList(returnData));
    } catch (error) {
        // Extracts and handles the error message from the API response.
        const errorMessage = error.response?.data?.message || error.message;
        dispatch(setNoteList([]));
    }
};

  /**
   * Initiates the a request to get jobs list.
   * @param {Object} payLoad - Contains the filter details.
   * @returns {Promise} - Returns the server response or handles the error.
   */
  export const getMatchedJobsStats = (payLoad) => async (dispatch) => {
    // Indicates the start of the Add new jobrequest process.
    dispatch(matchedJobsRequest());

    try {
        // Makes an API call to register the invited user.
        const response = await apiService.post('jobs/list', payLoad);
        const returnData = response.data?.result;

        // Updates the Redux store with the registered employer details.
        dispatch(setMatchedJobsList(returnData));
    } catch (error) {
        // Extracts and handles the error message from the API response.
        const errorMessage = error.response?.data?.message || error.message;
        dispatch(setMatchedJobsList([]));
    }
};

/**
   * Initiates the a request to get following community list.
   * @param {Object} payLoad - Contains the filter details.
   * @returns {Promise} - Returns the server response or handles the error.
   */
export const getFollowingCommunities = (payLoad) => async (dispatch) => {
    // Indicates the start of the Add new jobrequest process.
    dispatch(employerRegisterRequest());

    try {
        // Makes an API call to register the invited user.
        const response = await apiService.post('community/followers-list', payLoad);
        const returnData = response.data?.result;

        // Updates the Redux store with the registered employer details.
        dispatch(setFollowingCommunities(returnData));
    } catch (error) {
        // Extracts and handles the error message from the API response.
        const errorMessage = error.response?.data?.message || error.message;
        dispatch(setFollowingCommunities([]));
    }
};

/**
   * Initiates the a request to get subscribed community list.
   * @param {Object} payLoad - Contains the filter details.
   * @returns {Promise} - Returns the server response or handles the error.
   */
export const getSubscribedCommunities = (payLoad) => async (dispatch) => {
    // Indicates the start of the Add new jobrequest process.
    dispatch(subscribeListRequest());

    try {
        // Makes an API call to get subscribed community list.
        const response = await apiService.post('auth/subscribed-list', payLoad);
        const returnData = response.data?.result;

        // Updates the Redux store with the subscribed community list.
        dispatch(setSubscribedCommunities(returnData));
    } catch (error) {
        // Extracts and handles the error message from the API response.
        const errorMessage = error.response?.data?.message || error.message;
        dispatch(setSubscribedCommunities([]));
    }
};

  /**
   * Initiates the a request to upload file with External urls and import the jobs.
   * @param {Object} payLoad - Contains the details of bulk External jobs upload payload.
   * @returns {Promise} - Returns the server response or handles the error.
   */
  export const bulkExternalUpload = (payLoad) => async (dispatch) => {
    // Indicates the start of the bulk External job upload request process.
    dispatch(employerRegisterRequest());
    const config = {
        headers: {
          'Content-Type':  'multipart/form-data',
          // You can add more headers here if needed
        },
      }

    try {
        // Makes an API call to get list of notes.
        const response = await apiService.post('jobs/external-bulk-upload', payLoad,config);
        const returnData = response.data?.result?.message;

        // Updates the Redux store with the response.
        dispatch(bulkExternalUploadSuccess(returnData));
    } catch (error) {
        // Extracts and handles the error message from the API response.
        const errorMessage = error.response?.data?.message || error.message;
        dispatch(bulkExternalUploadFailed(errorMessage));
    }
};
  /**
   * Initiates the a request to get bulk External jobs import list.
   * @param {Object} payLoad - Contains the filter details.
   * @returns {Promise} - Returns the server response or handles the error.
   */
  export const getExternalJobsList = (payLoad) => async (dispatch) => {
    // Indicates the start of the bulk jobs import request process.
    dispatch(employerRegisterRequest());

    try {
        // Makes an API call to get bulk jobs import list.
        const response = await apiService.post('jobs/external-jobs-list', payLoad);
        const returnData = response.data?.result;

        // Updates the Redux store with the bulk jobs import list.
        dispatch(setExternalJobsList(returnData));
    } catch (error) {
        // Extracts and handles the error message from the API response.
        const errorMessage = error.response?.data?.message || error.message;
        dispatch(setExternalJobsList([]));
    }
};

export const getMatchedCandidates = (payLoad) => async (dispatch) => {
   // dispatch(jobsListRequest());

    try {
        const response = await apiService.post('/jobs/matching-profiles-list', payLoad);
        const returnData = response.data?.result?.list;

 //dispatch(setJobsList(returnData));

        return returnData; // Return the result
    } catch (error) {
            const errorMessage = error.response?.data?.message || error.message;
          //  dispatch(setJobsList([]));
            throw errorMessage; // Throw the error message
    }
};

//getJoinedGroups
/**
   * Initiates the a request to get subscribed community list.
   * @param {Object} payLoad - Contains the filter details.
   * @returns {Promise} - Returns the server response or handles the error.
   */
export const getJoinedGroups = (payLoad) => async (dispatch) => {
    // Indicates the start of the Add new jobrequest process.
    dispatch(joinedGroupRequest());

    try {
        // Makes an API call to get subscribed community list.
        const response = await apiService.post('employer/joined-communities', payLoad);
        const returnData = response.data?.result;

        // Updates the Redux store with the subscribed community list.
        dispatch(setJoinedGroups(returnData?.[0]));
    } catch (error) {
        // Extracts and handles the error message from the API response.
        const errorMessage = error.response?.data?.message || error.message;
        dispatch(setJoinedGroups([]));
    }
};

//get recent logged in users

export const getRecentLogins = (payLoad) => async (dispatch) => {
    dispatch(recentLoginsReq());
 
     try {
         const response = await apiService.post('employer/team-list', payLoad);
         const returnData = response.data?.result;
 
         dispatch(setRecentLogins(returnData));
     } catch (error) {
             const errorMessage = error.response?.data?.message || error.message;
            dispatch(setRecentLogins([]));
     }
 };


//getEmpCandidatesMatches
export const getEmpCandidatesMatches = (payLoad) => async (dispatch) => {
    dispatch(empCandidatesMatchesReq());
 
     try {
         const response = await apiService.post('employer/matched-candidated', payLoad);
         const returnData = response.data?.result;
 
         dispatch(setEmpCandidateMatches(returnData));
     } catch (error) {
             const errorMessage = error.response?.data?.message || error.message;
            dispatch(setEmpCandidateMatches([]));
     }
 };

 export const getEmpTopSkills = (payLoad) => async (dispatch) => {
    dispatch(empTopSkillsReq());
 
     try {
         const response = await apiService.post('employer/top-skills', payLoad);
         const returnData = response.data?.result;
         dispatch(setEmpTopSkills(returnData));
     } catch (error) {
             const errorMessage = error.response?.data?.message || error.message;
            dispatch(setEmpTopSkills([]));
     }
 };

 /**
 * To get the most interviewed stats for a employer
 *
 * @param {Object} payLoad - Contains most interviewed stats details.
 * @returns {Promise} - Returns the server response or throws an error.
 */
export const getInterviewerStats = ({type}) => async (dispatch) => {
    // Dispatches an action to indicate the most interviewed stats request has started.
    let payLoad = {
      "page":1,
      "perpage":5, 
      "interviewType": type // 1 for Interviewed, 2 for Rejected, 3 for Hired
  }
  switch (type){
    case 1:
      dispatch(interviewStatsRequest())
      break;
    case 2:
      dispatch(rejectedStatsRequest())
      break;
    case 3:
      dispatch(hireStatsRequest())
      break;
    default:
      break;
  }
  
    try {
      // Makes an API call to get most interviewed stats.
      const response = await apiService.post("employer/interviewer-details", payLoad);
      const returnData = response.data?.result;
  
      // Dispatches an action to store the get most interviewed stats in the redux store.
      switch (type) {
        case 1:
          dispatch(setInterviewdStats(returnData?.[0]));
          break;
        case 2:
          dispatch(setRejectedStats(returnData?.[0]));
          break;
        case 3:
          dispatch(setHiredStats(returnData?.[0]));
          break;
        default:
          return null
          break;
      }
    } catch (error) {
      // Handles any errors that occur during the API request.
      const errorMessage = error.response?.data?.message || error.message;
  
      // Dispatches an action to show get most interviewed stats request failed.
      switch (type) {
        case 1:
          dispatch(setInterviewdStats([]));
          break;
        case 2:
          dispatch(setRejectedStats([]));
          break;
        case 3:
          dispatch(setHiredStats([]));
          break;
        default:
          return null
        break;
      }
    }
  };

  export const getHiredAvgDays = (payLoad) => async (dispatch) => {
    dispatch(hiredAvgDaysRequest());
 
     try {
         const response = await apiService.post('employer/days-to-update-status', payLoad);
         const returnData = response.data?.result?.[0];
         dispatch(setHiredAvgDays(returnData));
     } catch (error) {
             const errorMessage = error.response?.data?.message || error.message;
            dispatch(setHiredAvgDays([]));
     }
 };

 
 export const getRejectedAvgDays = (payLoad) => async (dispatch) => {
    dispatch(rejectedAvgDaysRequest());
 
     try {
         const response = await apiService.post('employer/days-to-update-status', payLoad);
         const returnData = response.data?.result?.[0];
         dispatch(setRejectedAvgDays(returnData));
     } catch (error) {
             const errorMessage = error.response?.data?.message || error.message;
            dispatch(setRejectedAvgDays([]));
     }
 };
 
  export const getRecentMessages = (payLoad) => async (dispatch) => {
    dispatch(recentMessagesReq());
 
     try {
         const response = await apiService.post('employer/recent-messages', payLoad);
         const returnData = response.data?.result?.[0];
         dispatch(setRecentMessages(returnData));
     } catch (error) {
             const errorMessage = error.response?.data?.message || error.message;
            dispatch(setRecentMessages([]));
     }
 };

 //getTopJobsApplied
 export const getTopJobsApplied = (payload) => async (dispatch) => {
    dispatch(requestTopAppliedJobs());
    try{
        const response = await apiService.post('employer/applied-jobs-count', payload);
        const returnData = response.data?.result;
        dispatch(setTopAppliedJobs(returnData))
    }
    catch(error){
        dispatch(setTopAppliedJobs([]));
    }

 }

  export const getAppliedTalentList = (payload) => async (dispatch) => {
    dispatch(requestAppliedTalent());
    try{
        const response = await apiService.post('employer/applied-talent-details', payload);
        const returnData = response.data?.result;
        dispatch(setAppliedTalentList(returnData))
    }
    catch(error){
        dispatch(setAppliedTalentList([]));
    }

 }