import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//import Select from 'react-select'
import { Controller } from "react-hook-form";
import "@/assets/styles/sampleSelect.scss";
import CreatableSelect from "react-select/creatable";
import Form from "react-bootstrap/Form";
import { checkProperty } from "../utils/helpers";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Information from './icons/information';
import { addMasterData } from "../services/commonServices"
import { masterAddReset } from "../redux/slices/commonSlice"

function SimpleSelect({
  options,
  isMulti,
  placeholder,
  control,
  defaultValue,
  onChange,
  label,
  isrequired,
  isValidationRequired,
  selectedOption,
  className,
  errors,
  register,
  isRequired,
  errorText,
  fieldName,
  onInputChange,
  trackBy = "id",
  setRefValue,
  valueMax = 100,
  toolTipInfo = "",
  type,
  masterCate = '',
  onOptionAdded
}) {
  const { addedOption } = useSelector(state => state.common)
  const dispatch = useDispatch();
  //const [selectedValue,setSelectedOptions] = useState("");

  // const handleChange = (selectedOptions) => {
  //   setSelectedOptions(selectedOptions);
  //   console.log("selectedOptions",selectedOptions)
  // };

  const formatOptionLabel = (option) => {
    if (type === "state_name" && option && option.stateName) {
      return option.name + " (" + option?.stateName + ")";
    }
    return option.name;
  };

  // Handle creation of a new option
  const handleCreate = (inputValue) => {
    const newOption = { value: inputValue.toLowerCase(), label: inputValue, name: inputValue, };
    // setOptions([...options, newOption]);
    // setSelectedValue(newOption);
    if (inputValue?.length > 2 && masterCate) {
      let payload = {
        "category": masterCate,
        "name": inputValue
      }
      dispatch(addMasterData(payload));
    }

  };

  useEffect(() => {
    // alert(addedOption)
    if (addedOption) {
      if (onOptionAdded) {
        onOptionAdded(masterCate, addedOption)
      }
      dispatch(masterAddReset())
    }

  }, [addedOption]);


  return (
    <div className="simpleselect_container form-group">
      {label && <Form.Label>{isRequired ? label + "*" : label}
        {toolTipInfo && <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip>
              {toolTipInfo}
            </Tooltip>
          }
        >
          <span variant="secondary">
            <Information></Information>
          </span>
        </OverlayTrigger>}
      </Form.Label>}
      <Controller
        name={fieldName}
        id={fieldName}
        control={control}
        defaultValue={defaultValue}
        rules={{ required: isRequired }}
        render={({ field }) => (
          <CreatableSelect
            {...field}
            options={options.map((option) => ({
              ...option,
              label: option.name,
              value: option.id,
            }))}
            ref={(refId) => {
              if (setRefValue) {
                setRefValue(fieldName, refId);
              }
            }}
            menuPlacement="auto"
            name={field.name}
            value={field.value}
            isMulti={isMulti}
            placeholder={placeholder}
            onChange={(selectedOptions, event) => {
              if (
                isMulti &&
                valueMax &&
                Array.isArray(selectedOptions) &&
                selectedOptions.length > valueMax
              )
                return;

              field.onChange(selectedOptions, event);
              onInputChange(selectedOptions, event, fieldName);
              //console.log("actionMeta",actionMeta)
              //handleChange(selectedOptions)
            }}
            className={
              className
                ? "simpleselect " + className + (isMulti ? " multi_select" : "")
                : "simpleselect " + (isMulti ? "multi_select" : "")
            }
            classNamePrefix="simpleselect"
            formatOptionLabel={formatOptionLabel}
            onCreateOption={handleCreate}
          //onChange={onChange}
          //onChange={handleChange}
          //controlShouldRenderValue={false}
          //hideSelectedOptions={false}
          //isClearable={true}
          //closeMenuOnSelect={false}
          />
        )}
      />

      {errors &&
        fieldName &&
        checkProperty(fieldName.split("."), "length") > 2 &&
        checkProperty(
          errors,
          fieldName.split(".")[0],
          fieldName.split(".")[1]
        ) &&
        checkProperty(
          errors[fieldName.split(".")[0]][fieldName.split(".")[1]],
          fieldName.split(".")[2],
          "type"
        ) === "required" && (
          <span className="form-error">{"* " + errorText + " is required"}</span>
        )}

      {errors &&
        fieldName &&
        checkProperty(fieldName.split("."), "length") > 1 &&
        checkProperty(
          errors,
          fieldName.split(".")[0],
          fieldName.split(".")[1]
        ) &&
        checkProperty(
          errors[fieldName.split(".")[0]],
          fieldName.split(".")[1],
          "type"
        ) === "required" && (
          <span className="form-error">{"* " + errorText + " is required"}</span>
        )}
      {errors[fieldName] && (
        <span className="form-error">{"* " + errorText} is required</span>
      )}
    </div>
  );
}
export default SimpleSelect;
