import React, {useState,useEffect} from 'react';
import '@/assets/styles/Jobs.scss';
// import Slogo from '@/assets/images/S.svg';
// import layerpic from '@/assets/images/Layer.svg';
import { useSelector, useDispatch } from 'react-redux';
import { getAppliedTalentList, getJobDetails } from '../../services/employerService';
import { isEmpty,getTrimmedContent, getLogoLetters } from '../../utils/helpers';
import Moment from 'moment';
import { Tab, Tabs } from 'react-bootstrap';
import ProfileLogo from "@/assets/images/icons/profile-icon.svg";
import NoData from '../../components/NoData';
import ProfileModal from '../Profile/profile';

const salaryType = [
    { id: 1, name: 'Hourly' },
    { id: 2, name: 'Monthly' },
    { id: 3, name: 'Annually' },
  ]
function JobDetails({jobId}) {
    const dispatch = useDispatch();
    const { jobDetails, appliedTalent, appliedListLoading } = useSelector(state => state.employer);
    const [showStudentDetails, setshowStudentDetails] = useState({
        show: false,
        detailsId: '',
        userId: '',
        jobId: '',
        defaultActiveKey: "Basic Information"
    });
    useEffect(() => {
        dispatch(getJobDetails({"jobId": jobId}))
        dispatch(getAppliedTalentList({"jobId": jobId,
    "page" : 1,
"perpage": 40}))
    }, [jobId])
    const showDetails = ({ detailsId, jobId, userId, tabTitle }) => {
        setshowStudentDetails(() => ({
            show: true,
            detailsId: detailsId,
            userId: userId,
            jobId: jobId,
            defaultActiveKey: tabTitle
        }))
    }
    const closePopup = () => {
        setshowStudentDetails(() => ({
            show: false,
            detailsId: '',
            userId: '',
            jobId: '',
            defaultActiveKey: 'Basic Information'
        }))
    }
    return (
        <React.Fragment>
            {!isEmpty(jobDetails)&&<div className="description">
                <div className="job-title">
                    <h4>{!isEmpty(jobDetails.jobTitle)?jobDetails.jobTitle:"--"}</h4>
                    <p className='job-location'>    {jobDetails.jobLocationsDetails&&jobDetails.jobLocationsDetails.length>0&&jobDetails.jobLocationsDetails.map((item)=>
                             <span>{item.name} { item?.stateName ? `(${item.stateName})` : ""}</span>
                           
                          )}</p>
                
                </div>
                <div className="cards-container">
                    <div className="cards">
                        <div>
                            <h3>Employment Type</h3>
                            {jobDetails.jobTypeDetails&&jobDetails.jobTypeDetails.length>0&&jobDetails.jobTypeDetails.map((item)=>
                                <label>{item.name}</label>
                         )}
                        </div>
                    </div>
                     <div className="cards">
                        <div>
                            <h3>Experience (years)</h3>
                            <label>Min -{!isEmpty(jobDetails?.minExperience)?jobDetails.minExperience:"-"}  Max - {!isEmpty(jobDetails?.maxExperience)?jobDetails.maxExperience:"-"}</label>
                        </div>
                    </div> 
                     <div className="cards">
                        <div>
                            <h3>Threashold</h3>
                            <label>{!isEmpty(jobDetails.threshold)?(jobDetails.threshold)+"%":"--"}</label>
                        </div>
                    </div> 
                    <div className="cards">
                        <div>
                            <h3>Salary ({jobDetails?.salaryType ?  salaryType.find((t) => t.id == jobDetails.salaryType)?.name : "Hourly"})</h3>
                            <label>{!isEmpty(jobDetails.salaryRange)?(jobDetails.salaryRangeDetails?.[0]?.name):"--"}</label>
                        </div>
                    </div>
                </div>
                <Tabs defaultActiveKey="jobDetails">
                    <Tab eventKey="jobDetails" title="Job Details">
                <div className="data">
                    <h5>Company</h5>
                    <p>{!isEmpty(jobDetails?.companyName)?getTrimmedContent(jobDetails.companyName):"--"}</p>
                </div>
                <div className="data">
                    <h5>Description</h5>
                    <p>{!isEmpty(jobDetails.jobDescription)?getTrimmedContent(jobDetails.jobDescription):"--"}</p>
                </div>
                <div className="data">
                    <h5>Are you a US Citizen?</h5>
                    <p>{jobDetails.areYouUsCitizen? "Yes" :"No"}</p>
                </div>
                <div className="data">
                    <h5>Are you willing to relocate?</h5>
                    <p>{jobDetails.relocate? "Yes" :"No"}</p>
                </div>
                <div className="data">
                    <h5>Do you or will you require sponsorship for a US Employment Visa?</h5>
                    <p>{jobDetails.sponsorForUsEmployVisa? "Yes" :"No"}</p>
                </div>
                <div className="data">
                    <h5>Do you require a degree for this role?</h5>
                    <p>{jobDetails?.requireDegree ? "Yes" : "No"}</p>
                </div>
                <div className="data">
                    <h5>Major</h5>
                    <p>{jobDetails.fieldStudyDetails?.length > 0 ? jobDetails.fieldStudyDetails[0].name :"--"}</p>
                </div>
                <div className="data">
                    <h5>GPA</h5>
                    <p>{jobDetails.gpa ? jobDetails.gpa :"--"}</p>
                </div>
                <div className="data">
                    <h5>Graduation Year</h5>
                    <p>{jobDetails.graduationYr ? jobDetails.graduationYr :"--"}</p>
                </div>
                <div className="data">
                    <h5>Previous Job Titles</h5>
                    <ul className="filters">
                    {jobDetails?.previousJobDetails&&jobDetails.previousJobDetails.length>0&&jobDetails.previousJobDetails.map((item)=>
                    
                        <li>
                            <label>{item.name}</label>
                        </li>
                    )}
                    </ul>
                </div> 
                
                <div className="data">
                    <h5>Required Skills</h5>
                    <ul className="filters">
                    {jobDetails.technicalSkillsDetails&&jobDetails.technicalSkillsDetails.length>0&&jobDetails.technicalSkillsDetails.map((item)=>
                    
                    <li>
                        <label>{item.name}</label>
                    </li>
                )}
                    {jobDetails.softSkillsDetails&&jobDetails.softSkillsDetails.length>0&&jobDetails.softSkillsDetails.map((item)=>
                    
                        <li>
                            <label>{item.name}</label>
                        </li>
                    )}
                  
                    </ul>
                </div>
                
                <div className="data">
                    <h5>Job Status</h5>
                    <label>{!isEmpty(jobDetails.jobStatus)?jobDetails.jobStatus:"--" }</label>
                </div>
                <div className="data">
                    <h5>Job nationwide</h5>
                    <label>{!isEmpty(jobDetails.jobNationWide)?jobDetails.jobNationWide:"--" }</label>
                </div>
                <div className="data">
                    <h5>Job Url</h5>
                    <label>{!isEmpty(jobDetails.jobApplicationUrl)?jobDetails.jobApplicationUrl:"--"}</label>
                </div>
                </Tab>
                <Tab  eventKey="appliedTalent" title="Applied Talent">
                        {appliedTalent?.totalCount > 0 ? <div className='profile_dp_wrap profile_cards profile_cardsv2'>
                            {appliedTalent?.list?.map((user) => <div className='profile_dp'>
                                <div className='profile_icon'>
                                    <figure>
                                        {user?.userDetails?.profilePicture ?
                                            <img src={user?.userDetails?.profilePicture ? user?.userDetails?.profilePicture : ProfileLogo} />
                                            :
                                            <span className="icon">{getLogoLetters(user?.userDetails?.name)}</span>}
                                    </figure>
                                </div>
                                <label className='profile_name show-curser' 
                                 onClick={() => showDetails({ detailsId: user?.userDetails?._id, jobId, userId: user?.userId, tabTitle: "Basic" })}
                                >{user?.userDetails?.name}</label>
                            </div>)}
                        </div>
                        :
                        <NoData loading={appliedListLoading} />}
                </Tab>

                </Tabs>
                {/* <div className="data">
                    <h5>Expiration Date</h5>
                    <label>{!isEmpty(jobDetails.expiredDate)?Moment(jobDetails.expiredDate).format("YYYY-MM-DD"):"--" }</label>
                </div> */}
            </div>}
            {showStudentDetails.show &&
                <ProfileModal
                    show={showStudentDetails.show}
                    onHide={closePopup}
                    detailsId={showStudentDetails.detailsId}
                    isFromList={true}
                    jobMsgDetails={showStudentDetails}

                />}
        </React.Fragment>
        
    )
}
export default JobDetails;