import React from 'react'; 
//import { Link } from 'react-router-dom';
import footer_logo from './../../src/assets/images/footer-logo.png';
import instagram_logo from './../../src/assets/images/instagram-icon.svg';
import X_logo from './../../src/assets/images/X-icon.svg';
import fb_logo from './../../src/assets/images/facebook-icon.svg';
import linkedin_logo from './../../src/assets/images/linkedin-icon.svg';

import '@/assets/styles/footer.scss'
import { Container } from 'react-bootstrap';

function Footer() { 
    return (
        <div className='footer'> 
        <Container>
            <div className="footer_cnt ">
                <div className="footer_nav">
                <a href="" className="footer_logo">
                    <img src={footer_logo}></img></a>
                  
                </div>
                <div className="social_networks">
                    <label>Copyright &copy; 2024 Matchplicity LLC. All Rights Reserved.</label>
              
                </div>
            </div>
        </Container>            
        </div>
    )

}
export default Footer;