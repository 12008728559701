// Importing Redux actions specific to talent registration.
import {
    talentRegisterRequest,
    talentRegisterSuccess,
    talentRegisterFailure,
    workTypesData,
    benefitsData,
    ethniCityData,
    fieldStudyData,
    genderData,
    healthData,
    idealWorkData,
    leaderShipData,
    jodSearchData,
    recreationalData,
    workEnvironmentData,
    talentListRequested,
    talentListSuccess,
    talentListFailure,
    talentQuestionnareFailure,
    talentQuestionnareSuccess,
    talentDetailsResponse,
    jobTitlesData, preferredCertificatesData, salaryRangeData, skillsData, jobLocations, tradsData, certificationsData, jobTypeGoalsData, wagesData,setJobProfileStatus,
    setCompanySize,
    setTopicStatus,
    setDiscussionStatus,
    setJobsList,
    setApplyJobSuccess,
    setApplyJobFailure,
    eventStatusData,
    setCareers,
    setIndustries,
    setMajor,
    setUniversityMajor,
    setCommunityTags,
    setInvitePopupData,
    setAgreedPrivacySuccess,
    setAgreedPrivacyFailed,
    talentJobsRequest,
    talentQSubmitRequest,
    setStudentTypes

} from '../redux/slices/talentSlice';
//import {studentProfileCompleted} from "../redux/slices/authSlice"
// Service to interact with the backend API.
import {apiService} from './apiService';

// Utility function to display user notifications as toast messages.
import { showToast } from '../views/Utils';

/**
 * Handles the registration process for a talent using an invitation.
 * 
 * @param {Object} payLoad - Contains the details required for talent registration.
 * @returns {Promise} - If successful, updates the Redux store with talent details, otherwise dispatches an error.
 */
export const talentRegister = (payLoad) => async (dispatch) => {
    // Dispatches an action to signal that the talent registration request has started.
    dispatch(talentRegisterRequest());

    try {
        // Sends a POST request to register the invited talent.
        const response = await apiService.post('users/invited-user-register', payLoad);
        const returnData = response.data?.result;

        // If successful, updates the Redux store with the details of the newly registered talent.
        dispatch(talentRegisterSuccess(returnData));
    } catch (error) {
        // Handles errors that might occur during the API call.
        const errorMessage = error.response?.data?.message || error.message;

        // Dispatches an action signaling a failure in the talent registration process.
        dispatch(talentRegisterFailure(errorMessage));

        // Displays the error to the user via a toast notification.
        showToast(errorMessage, 'error');
    }
};
export const getTalentMasterData = ({ category, subCategory, id }) => async (dispatch) => {
    // let matcher = (category == "skills") ? { "category": subCategory } : {}
    const payLoad = category === "certifications"
        ? { "matcher": { "tradeId": id }, "category": category, "page": 1, "perpage": 2500 }
        : { "category": category, "page": 1, "perpage": 20000 };


    try {
        const response = await apiService.post('masterdata/list', payLoad);
        const returnData = response.data?.result?.list;
        // Determine the type of data retrieved and dispatch the appropriate action.
        switch (category) {
            case 'work_types':
                dispatch(workTypesData(returnData));
                break;
            case 'benefits':
                dispatch(benefitsData(returnData));
                break;
            case 'ethnicity':
                dispatch(ethniCityData(returnData));
                break;
            case 'field_study':
                dispatch(fieldStudyData(returnData));
                break;
            case 'gender':
                dispatch(genderData(returnData));
                break;
            case 'health_wellness_activities':
                dispatch(healthData(returnData));
                break;
            case 'ideal_work':
                dispatch(idealWorkData(returnData));
                break;
            case 'leadership_development':
                dispatch(leaderShipData(returnData));
                break;
            case 'job_search':
                dispatch(jodSearchData(returnData));
                break;
            case 'recreational_activities':
                dispatch(recreationalData(returnData));
                break;
            case 'skills':
                dispatch(skillsData(returnData));
                break;
            case 'salary_ranges':
                dispatch(salaryRangeData(returnData));
                break;
            case 'preferred_certifications':
                dispatch(preferredCertificatesData(returnData));
                break;
            case 'previous_job_titles':
                dispatch(jobTitlesData(returnData));
                break;
            case 'locations':
                dispatch(jobLocations(returnData))
                break;
            case 'trades':
                dispatch(tradsData(returnData))
                break;
            case 'certifications':
                dispatch(certificationsData(returnData))
                break;
            case 'job_type_goals':
                dispatch(jobTypeGoalsData(returnData))
                break;
            case 'hourly_wages':
                dispatch(wagesData(returnData))
                break;
            case 'profile_job_status':
                dispatch(setJobProfileStatus(returnData))
                break;
case 'preferred_company_size':
                dispatch(setCompanySize(returnData))
                break;
                case 'topic_status':
                dispatch(setTopicStatus(returnData))
                break;
                case 'discussion_status':
                dispatch(setDiscussionStatus(returnData))
                break;
                case 'event_status':
                dispatch(eventStatusData(returnData))
                break;
                case 'career_levels':
                dispatch(setCareers(returnData))
                break;
                case 'industries':
                dispatch(setIndustries(returnData))
                break;
                case 'major' : 
                dispatch(setMajor(returnData))
                break;
                case 'university_major':
                    dispatch(setUniversityMajor(returnData))
                    break;
                case 'community_tags':
                    dispatch(setCommunityTags(returnData))
                    break;
                case 'student_types':
                    dispatch(setStudentTypes(returnData))
                    break;
            // case 'trades':
            //     dispatch(primaryCertificationsData(returnData))











            default:
                dispatch(workEnvironmentData(returnData));
        }

        return response;
    } catch (error) {
        // Handling errors for all categories via a general action.
        // In a more advanced setup, different actions could be dispatched for different error scenarios.
        const errorMessage = error.response?.data?.message || error.message;
    }
};


/**
* To get the latest talent List
* 
* @param {Object} payLoad - Contains list filtering details.
* @returns {Promise} - Returns the server response or throws an error.
*/
export const getTalentList = (payLoad,cancelTokenSource) => async (dispatch) => {
    // Dispatches an action to indicate the registration request has started.
    dispatch(talentListRequested())

    try {
        // Makes an API call to register the invited user.
        const response = await apiService.post('users/list', payLoad,{ signal: cancelTokenSource?.signal});
        const returnData = response.data?.result;

        // Dispatches an action to store the get talent list in the redux store.
        dispatch(talentListSuccess(returnData));
    } catch (error) {
        // Handles any errors that occur during the API request.
        const errorMessage = error.response?.data?.message || error.message;

        // Dispatches an action to show get talent list request failed.
        dispatch(talentListFailure(errorMessage));

    }
};
export const talentQuestionnareSubmit = (payLoad) => async (dispatch) => {
    dispatch(talentQSubmitRequest())

    try {
        const response = await apiService.post('users/update', payLoad);
        const returnData = response.data?.message;
        dispatch(talentQuestionnareSuccess(returnData));
        //dispatch(studentProfileCompleted());
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message;
        dispatch(talentQuestionnareFailure(errorMessage));

    }
};
export const talentDetails = (payLoad) => async (dispatch) => {
    try {
        const response = await apiService.post('users/details', payLoad);
        const returnData = response.data?.result;
        dispatch(talentDetailsResponse(returnData));
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message;
    }
};



export const getMasterDataWithoutDispatch = ({ category, subCategory, id }) => {
    const payLoad = category === "certifications"
        ? { "matcher": { "tradeId": id }, "category": category, "page": 1, "perpage": 2500 }
        : category ==="locations"?{ "matcher": { "stateId": id }, "category": category, "page": 1, "perpage": 500 }
        :category ==="states"?{ "matcher": { "countryId": id }, "category": category, "page": 1, "perpage": 6000 }
        :{ "category": category, "page": 1, "perpage": 6000 };
    return new Promise(async (resolve, reject) => {
        try {
            const response = await apiService.post('masterdata/list', payLoad);
            const returnData = response.data?.result?.list;
            resolve(returnData)
        } catch (error) {
            reject(error);
        }
    });
};

/**
* To get the latest jobs List
* 
* @param {Object} payLoad - Contains list filtering details.
* @returns {Promise} - Returns the server response or throws an error.
*/
export const getJobsList = (payLoad,cancelTokenSource) => async (dispatch) => {
    // Dispatches an action to indicate the get jobs list request has started.
    dispatch(talentJobsRequest())

    try {
        // Makes an API call to get jobs list.
        const response = await apiService.post('users/matching-jobs-list', payLoad,{ signal: cancelTokenSource?.signal});
        const returnData = response.data?.result;

        // Dispatches an action to store the get jobs list in the redux store.
        dispatch(setJobsList(returnData));
    } catch (error) {
        // Handles any errors that occur during the API request.
        const errorMessage = error.response?.data?.message || error.message;

        // Dispatches an action to show get talent list request failed.
        dispatch(setJobsList([]));
        
    }
};
/**
* To apply for a job
* 
* @param {Object} payLoad - Contains talent/student and job details.
* @returns {Promise} - Returns the server response or throws an error.
*/
export const applyJob = (payLoad) => async (dispatch) => {
    // Dispatches an action to indicate the apply job request has started.
    dispatch(talentRegisterRequest())

    try {
        // Makes an API call to apply job.
        const response = await apiService.post('users/apply-now', payLoad);
        const returnData = response.data?.result.message;

        // Dispatches an action to store the apply job success in the redux store.
        dispatch(setApplyJobSuccess(returnData));
    } catch (error) {
        // Handles any errors that occur during the API request.
        const errorMessage = error.response?.data?.message || error.message;
        console.log("error in applied job ",errorMessage)

        // Dispatches an action to show get apply job request failed.
        dispatch(setApplyJobFailure(errorMessage));
        
    }
};

/**
* To get the user invite payment - required or not
* 
* @param {Object} payLoad - Contains user details.
* @returns {Promise} - Returns the server response or throws an error.
*/
export const getPaymentRequiredOrNot = (payLoad) => async (dispatch) => {
    // Dispatches an action to indicate the get jobs list request has started.
    dispatch(talentRegisterRequest())

    try {
        // Makes an API call to get jobs list.
        const response = await apiService.post('users/invite-pay-confirmation', payLoad);
        const returnData = response.data?.result;

        // Dispatches an action to store the get jobs list in the redux store.
        dispatch(setInvitePopupData(returnData));
    } catch (error) {
        // Handles any errors that occur during the API request.
        const errorMessage = error.response?.data?.message || error.message;

        // Dispatches an action to show get talent list request failed.
        dispatch(setInvitePopupData(errorMessage));
        
    }
};

//updateAgreePrivacy
export const updateAgreePrivacy = (payLoad) => async (dispatch) => {
    // Dispatches an action to indicate the agreement to privacy policy, terms & conditions request has started.
    dispatch(talentRegisterRequest())

    try {
        // Makes an API call to update agreement to privacy policy, terms & conditions
        const response = await apiService.post('users/update-signup-terms', payLoad);
        const returnData = response.data?.result?.message;

        // Dispatches an action to store the update agreement to privacy policy, terms & conditions response in the redux store.
        dispatch(setAgreedPrivacySuccess(returnData));
    } catch (error) {
        // Handles any errors that occur during the API request.
        const errorMessage = error.response?.data?.message || error.message;

        // Dispatches an action to show update agreement to privacy policy, terms & conditions request failed.
        dispatch(setAgreedPrivacyFailed(errorMessage));
        
    }
};

// scanEventUrl
export const scanEventUrl = (payLoad) => async (dispatch) => {
    // Dispatches an action to increment scan count for event.

    try {
        // Makes an API call to update increment scan count for event
        const response = await apiService.post('events/event-scanned-count', payLoad);
        const returnData = response.data?.result?.message;
    } catch (error) {
        // Handles any errors that occur during the API request.
        const errorMessage = error.response?.data?.message || error.message;
    }
};

export const getMdOptions = ({ category, subCategory, title, id, cancelTokenSource}) => {
    let payload = { 
        "matcher": { "title": title ? title : "" },
        "category": category, 
        "subCategory": subCategory, 
        "page": 1, 
        "perpage": 13000 }

    return new Promise(async (resolve, reject) => {
        try {
            const response = await apiService.post('masterdata/list', payload, {
                signal: cancelTokenSource?.signal
            });
            const returnData = response.data?.result?.list;
            resolve(returnData)
        } catch (error) {
            reject(error);
        }
    });
};

export const getOptionsList = ({ payload, endPoint, cancelTokenSource}) => {

    return new Promise(async (resolve, reject) => {
        try {
            const response = await apiService.post(endPoint, payload, {
                signal: cancelTokenSource?.signal
            });
            const returnData = response.data?.result?.list;
            resolve(returnData)
        } catch (error) {
            reject(error);
        }
    });
};