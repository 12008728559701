
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../redux/slices/userSlice';

let API_BASE_URL = '';
if (process.env.NODE_ENV === 'production') {
  API_BASE_URL = 'https://matchplicity.com/api';
} else {
  API_BASE_URL = 'https://matchplicity.com/api';
}

export const apiService = axios.create({
  baseURL: API_BASE_URL, //will move this to env
  headers: {
    'Content-Type': 'application/json'
  }
});

// Axios interceptor for requests
apiService.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  const communityId = (JSON.parse(localStorage.getItem('userinfo')))?.communityId
  if (communityId && (!config.data.communityId)){
    config.data.communityId =  communityId
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default {
  setUpInterceptors: (store) => {

    // Axios interceptor for responses
    apiService.interceptors.response.use((response) => {
      return response;
    }, (error) => {
      let appVersionId = JSON.parse(localStorage.getItem("userinfo"))?.appVersionId
      if ((error?.response?.data?.app_version && appVersionId) && (error.response.data.app_version != appVersionId)) {
        localStorage.clear()
        store.dispatch(logout())
        window.location.replace('/login')
      }
      //we need to work on not auth case/force logout case
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');  // Clear token if unauthorized
      }
      return Promise.reject(error);
    });
  }
}

